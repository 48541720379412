import React, { useState, useEffect } from "react"
import axios from "axios"
import { StaticImage } from "gatsby-plugin-image"

const OpeningTimes = () => {
  const [openingState, setOpeningState] = useState({
    status: "closed",
    openFrom: "",
    openTo: "",
    lastAdmission: ""
  })

  useEffect(() => {
    const getOpeningTimes = () => {
      axios.get(
        process.env.GATSBY_OPENING_TIMES_ENDPOINT
      ).then(function (response) {
        setOpeningState({
          status: response.data.status,
          openFrom: response.data.from,
          openTo: response.data.to,
          lastAdmission: response.data.last_admission
        })
      }).catch(function (error) {
        console.log(error)
      });
    }
    getOpeningTimes()
  }, [])

  return (
    <div className="py-2 text-white">
      {openingState.status === 'open'
        ? <span>
            <StaticImage 
              src="../assets/images/icon-clock.png"
              alt="Clock"
              className="me-2"
              height={22}
            />
            We are open today {openingState.openFrom} - {openingState.openTo}&nbsp;&nbsp;&nbsp;Last admission {openingState.lastAdmission}
          </span>
        : <span>
            <StaticImage 
              src="../assets/images/icon-clock.png"
              alt="Clock"
              className="me-2"
              height={22}
            />
            We are currently closed
          </span>
      }
    </div>
  )
}

export default OpeningTimes
import React, { useRef } from "react"
import { useHeaderMenu } from "../hooks/use-header-menu"
import Link from "../components/link"

const HeaderNavigation = () => {
    const [menuItems] = useHeaderMenu()
    
    return (
        <div className="d-none d-lg-block">
            <ul className="nav">
                {menuItems.map((node) => {
                    const { id = '', label = '', url = '' } = node
                    return (
                    <li className="nav-item ms-3" key={id}>
                        <Link
                        className="text-white text-decoration-none" 
                        to={url}>
                        {label}
                        </Link>
                    </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default HeaderNavigation
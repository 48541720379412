import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

import Link from "../components/link"
import { graphql, useStaticQuery } from "gatsby"
import { useHeaderMenu } from "../hooks/use-header-menu"

import LogoImg from "../assets/images/blackgang-logo-2022.png"

const Navigation = () => {
  const navButtonRef = useRef(null)
  const navContainerRef = useRef(null)
  const [headerMenuItems] = useHeaderMenu()

  const onToggleClick = (e) => {
    e.preventDefault()
    document.body.classList.toggle("no-scroll")
    navContainerRef.current.classList.toggle("show")
    navButtonRef.current.classList.toggle("close")
  }

  const navClick = (e) => {
    if (e.target.classList.contains('dropdown-toggle')) return;
    closeNav()
  }

  const closeNav = () => {
    document.body.classList.remove("no-scroll")
    navContainerRef.current.classList.remove("show")
    navButtonRef.current.classList.remove("close")
  }

  const data = useStaticQuery(graphql`
    {
      allWpMenuItem(
        filter: {locations: {eq: PRIMARY_MENU}, parentDatabaseId: {eq: 0}}
        sort: {fields: order, order: ASC}
      ) {
        edges {
          node {
            id
            title
            label
            childItems {
              nodes {
                label
                url
                id
              }
            }
            url
            cssClasses
          }
        }
        totalCount
      }
    }
  `)

  return (
    <React.Fragment>
      <button ref={navButtonRef} onClick={onToggleClick} className="nav-icon d-xxl-none mr-3" type="button" aria-label="Toggle navigation">
        <div></div>
      </button>

      <div ref={navContainerRef} className="collapse navbar-collapse align-items-stretch text-center">
        <img src={LogoImg} className="my-3 d-xl-none" height="80" alt="Blackgang Chine Logo"></img>

        <ul className="navbar-nav ms-auto text-center">
          {data.allWpMenuItem.edges.map((item) =>
            <li className={"nav-item d-xl-table align-self-xl-stretch " + (item.node.childItems && item.node.childItems.nodes.length > 0 ? "dropdown " : "") + item.node.cssClasses} key={item.node.id}>
              <Link to={item.node.url} onClick={navClick} className={"nav-link d-xl-table-cell align-middle" + (item.node.childItems && item.node.childItems.nodes.length > 0 ? " dropdown-toggle" : "")} data-bs-toggle={(item.node.childItems && item.node.childItems.nodes.length > 0 ? "dropdown" : "")}>
                {item.node.label}
              </Link>

              {item.node.childItems.nodes.length > 0 &&
                <ul className="dropdown-menu text-center text-xl-start">
                  {item.node.childItems.nodes.map((subitem) =>
                    <li key={subitem.id}>
                      <Link to={subitem.url} onClick={navClick} className="dropdown-item" activeClassName="active">
                        {subitem.label}
                      </Link>
                    </li>
                  )}
                </ul>
              }
            </li>
          )}
          <li className="nav-item d-xl-table align-self-xl-stretch book-tickets">
            <a href="https://blackgangchine.digitickets.co.uk/tickets" target="_blank" className="nav-link d-xl-table-cell align-middle">
              <StaticImage
                src="../assets/images/BookNowTicket.png"
                alt="Book Tickets"
                height={80}
                placeholder="none"
              />
            </a>
          </li>
        </ul>
        <div className="d-lg-none">
          <ul className="list-unstyled mt-4">
            {headerMenuItems.map((node) => {
              const { id = '', label = '', url = '' } = node
              return (
                <li className="mb-2" key={id}>
                  <Link
                    onClick={navClick}
                    className="text-white text-decoration-none"
                    to={url}>
                    {label}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </React.Fragment>

  )
}

export default Navigation
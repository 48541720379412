import React, { useEffect, useState } from "react"

const NewsletterFormF24 = () => {
  let email
  const [errors, setErrors] = useState({})
  const [status, setStatus] = useState(false)

  const submit = (e) => {
    e.preventDefault()
    if (Object.keys(errors).length === 0 && email && email.value) {
      setStatus("success")
      // Check if function is present
      if (typeof window !== 'undefined') {
        if (typeof window.f24 === 'function') {
          window.f24('event', 'fc_form_send', 'form#homepage-newsletter-signup')
        }
      }
    } else {
      setStatus("error")
    }
  }

  const handleChange = (e) => {
    e.persist()
    let name = e.target.name
    let value = e.target.value
    validate(e, name, value)
  }

  const validate = (e, name, value) => {
    switch (name) {
      case 'email':
        if (!value || !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)) {
          setErrors({
            ...errors,
            email: 'Enter a valid email address'
          })
        } else {
          delete errors.email
          setErrors(errors)
        }
        break
      default:
        break
    }
  }

  return (
    <div>
      {status === "error" &&
        <div className="text-white mb-3">
          {Object.keys(errors).map((key) => (
            <p key={key}>{errors[`${key}`]}</p>
          ))}
        </div>
      }

      {status === "success" && <div className="text-white">Thank you for subscribing!</div>}

      {status !== "success" &&
        <div>
          <div className="input-group">
            <label className="visually-hidden" htmlFor="newsletterEmail">Email</label>
            <input
              type="email"
              className={"form-control " + (errors.email !== undefined ? 'is-invalid' : '')}
              id="newsletterEmail"
              placeholder="Email"
              name="email"
              ref={node => (email = node)}
              onChange={handleChange}
            />
            <button onClick={submit} className="btn btn-secondary"><strong>Submit</strong></button>
          </div>
        </div>
      }
    </div>
  )
}

const NewsletterSignupF24 = () => {
  const [showForm, setShowForm] = useState(false)

  useEffect(() => {
    let interval = null

    // Check if the function is available on the window object
    if (window.f24) {
      // Function is available, you can use it here
      setShowForm(true)
    } else {
      // Function is not available yet, maybe it's loaded asynchronously
      // You can set up a timer to keep checking until it's available
      interval = setInterval(() => {
        if (window.f24) {
          clearInterval(interval)
          setShowForm(true)
        }
      }, 1000) // Check every second, you can adjust the interval as needed
    }

    // Cleanup function
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  return (
    (showForm === true ?
      <div className="page-footer__newsletter-signup">
        <div className="container p-5 text-center">
          <div className="row">
            <p className="col-12 col-lg-6 col-xl-8 offset-lg-3 offset-xl-2 h2 mb-5 text-secondary">
              Be the first to hear about new events, offers and competitions by joining our mailing list!
            </p>
          </div>
          <form className=" pb-5" id="homepage-newsletter-signup">
            <div className="col-12 col-md-6 offset-md-3">
              <NewsletterFormF24 />
            </div>
          </form>
        </div>
      </div>
      :
      ''
    )
  )
}
export default NewsletterSignupF24
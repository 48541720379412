import React from "react"
import LogoImg from "../assets/images/blackgang-logo-2022.png"

const HoldingPage = (holdingPageData) => {
    return (
        <section id="holdingPage">
            <div className="holding-page-inner">
                <img src={LogoImg} className="header__logo py-2" width="224" height="89" alt="Blackgang Chine Logo"></img>
                <h1>{holdingPageData.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: holdingPageData.message }}></div>
            </div>
        </section>
    )
}

export default HoldingPage
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/700.css"

import "./src/sass/app.scss"
import 'bootstrap/js/dist/button'
import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/alert'

import React from "react"
import Layout from "./src/components/layout"

// Wraps every page in a component
export const wrapPageElement = ({ element }) => <Layout>{element}</Layout>
import React, { useState, useEffect } from "react"
import axios from "axios"

import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

import Header from "./header"
import Footer from "./footer/footer"
import OpeningTimes from "./opening-times"
import NoticeBanner from "./notice-banner"
import CookieConsentBanner from "./cookie-consent-banner"
import PopUp from "./pop-up"
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo'
import HeaderNavigation from "./header-navigation"
import HoldingPage from "./holding-page"

const Layout = ({ children }) => {

  const [holdingPageState, setHoldingPageState] = useState({
    enable: false,
    title: "Page not currently avalible",
    message: "",
  })

  useEffect(() => {
    const getHoldingPage = () => {
      axios.get(
        process.env.GATSBY_HOLDING_ENDPOINT
      ).then(function (response) {
        setHoldingPageState({
          enable: response.data.enable,
          title: (response.data.title ? response.data.title : "Page not currently avalible"),
          message: response.data.message,
        })
      }).catch(function (error) {
        console.log(error)
      })
    }
    getHoldingPage()
  }, [])

  const {
    wp: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wp {
        seo {
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            twitter {
              username
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `)

  return (
    (holdingPageState.enable !== true ?
      <SEOContext.Provider value={{ global: seo }}>
        <Helmet>
          <meta name="facebook-domain-verification" content="z0r4qrr9q0d9n2uh2n1wou1jzz767c" />
        </Helmet>
        {children.props.location.pathname === '/jointtickets/' ? (
          <>{children}</>
        ) : (
          <>
            <div className="opening-times bg-primary-dark">
              <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                  <OpeningTimes />
                  <HeaderNavigation />
                </div>
              </div>
            </div>
            <NoticeBanner />
            <Header />
            {children}
            <Footer />
            <CookieConsentBanner />
            <PopUp />
          </>
        )}
      </SEOContext.Provider>
      :
      <HoldingPage {...holdingPageState} />
    )
  )
}

export default Layout
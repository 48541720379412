module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8165f50d859d8683600a10e591b34964"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"debug":{"preview":true,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"url":"https://admin.blackgangchine.com/graphql","html":{"useGatsbyImage":true,"generateWebpImages":true,"imageQuality":64,"createStaticFiles":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"User":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies-nbumbu/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-92752075-2","cookieName":"gatsby-gdpr-tracking","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-NVZB4CJ","cookieName":"gatsby-gdpr-tracking","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"622487903038405","cookieName":"gatsby-gdpr-tracking"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
